<template>
  <v-text-field
    v-bind="$attrs"
    v-model="inputValue"
  >
    <template
      #prepend-inner
    >
      <v-icon class="mt-1" size="18">mdi-currency-krw</v-icon>
    </template>
  </v-text-field>
</template>

<script>

export default {
  name: 'PriceText',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value.toLocaleString('ko-KR');
      },
      set(val) {
        this.$emit(
          'input',
          this.inputValue === 'NaN' ? 0 : Number(val.replaceAll(',', '')),
        );
      },
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">

</style>
