import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    unReadCnt: 0,
    notificationModal: true,
    notifications: [],
  },
  getters: {
    unReadCnt(state) {
      return state.unReadCnt;
    },
    notifications(state) {
      return state.notifications;
    },
    notificationModal(state) {
      return state.notificationModal;
    },
  },
  mutations: {
    unReadCnt(state, value) {
      state.unReadCnt = value;
    },
    notifications(state, value) {
      state.notifications = value;
    },
    notificationModal(state, value) {
      state.notificationModal = value;
    },
  },
  actions: {
    'socket.notification.notify': ({ state, commit, dispatch }, resp) => {
      dispatch('getUnreadCnt');
      commit('notifications', [...state.notifications, resp]);
      commit('notificationModal', true);
    },
    enterNotification({ state, commit, dispatch }, odj) {
      commit('dialog/progress', true, { root: true });
      const no = Number(odj.no);
      const redirect = String(odj.redirect);
      const unReads = state.notifications.filter((row) => row.no !== no);
      (new Vue()).$socket.emit('notification.read.update', {
        read: true,
        target: [no],
      }, async (resp) => {
        if (resp.result === 'success') {
          const contentsObj = await dispatch('getRedirectToObj', { redirect });
          if (contentsObj !== false) {
            const contentsCommandObj = {
              csNo: 'cs.get',
              postNo: 'board.post.get',
              saleNo: 'sales.get',
            };
            (new Vue()).$socket.emit(contentsCommandObj[contentsObj.key], {
              no: contentsObj.value,
            }, (response) => {
              if (response.result === 'success') {
                if (contentsObj.key === 'saleNo') {
                  commit('dialog/sale', {
                    show: true,
                    item: response.item,
                    func: () => false,
                  }, { root: true });
                } else {
                  commit('dialog/post', {
                    show: true,
                    item: response.item,
                    func: () => false,
                    type: contentsObj.key === 'csNo' ? 0 : 1,
                  }, { root: true });
                }
              } else {
                dispatch('dialog/alert', ['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`, false], { root: true });
                console.error(resp);
              }
              commit('dialog/progress', false, { root: true });
            });
          } else {
            commit('router/push', redirect, { root: true });
            commit('dialog/progress', false, { root: true });
          }
          commit('notifications', unReads);
          dispatch('getUnreadCnt');
        } else {
          dispatch('dialog/alert', ['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`, false], { root: true });
          commit('dialog/progress', false, { root: true });
          console.error(resp);
        }
      });
    },
    getUnreadCnt({ commit, dispatch }) {
      (new Vue()).$socket.emit('notification.summary.get', {
        filters: [{
          condition: 'eq',
          column: 'read',
          value: false,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          commit('unReadCnt', resp.item[0].count);
        } else {
          dispatch('dialog/alert', ['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`, false], { root: true });
          console.error(resp);
        }
      });
    },
    async transNotifictionType({ state }, type) {
      const result = await state.notificationTypes[type];
      return result;
    },
    // eslint-disable-next-line
    getRedirectToObj({ commit }, payload) {
      const { redirect } = { ...payload };
      const contentsKeyArr = ['csNo', 'saleNo', 'postNo'];
      let contentsObj = false;
      contentsKeyArr.forEach((r) => {
        if (redirect.indexOf(r) !== -1) {
          if (redirect.indexOf('&memo') !== -1) {
            contentsObj = {
              key: r,
              value: Number(redirect.split(`${r}=`)[1].split('&memo')[0]),
            };
          } else {
            contentsObj = {
              key: r,
              value: Number(redirect.split(`${r}=`)[1]),
            };
          }
        }
      });
      return contentsObj;
    },
  },
};
