// import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    list: [],
    show: false,
    result: {
      data: [],
      taskId: null,
      type: null,
      fileName: null,
      success: 0,
      fail: 0,
      show: false,
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
    show(state) {
      return state.show;
    },
    result(state) {
      return state.result;
    },
  },
  mutations: {
    list(state, value) {
      state.list = value;
    },
    show(state, value) {
      state.show = value;
    },
    result(state, value) {
      state.result = value;
    },
  },
  actions: {
    'socket.contact.add.progress': ({ state, commit }, resp) => {
      const newList = state.list.filter((r) => r.taskId !== resp.taskId);
      commit('list', newList);
      commit('list', [
        ...state.list,
        {
          status: resp.status,
          type: 'DB관리',
          total: resp.total,
          progress: resp.progress,
          fileName: resp.fileName,
          taskId: resp.taskId,
          duplicated: resp.duplicated,
        },
      ]);
    },
  },
};
