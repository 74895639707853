var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header-wrap",attrs:{"app":""}},[_c('div',{staticClass:"title-section"},[_c('v-btn',{attrs:{"icon":"","color":"#FFFFFF"},on:{"click":_vm.onDrawer}},[(_vm.drawer)?_c('v-icon',[_vm._v("mdi-menu")]):_vm._e(),(!_vm.drawer)?_c('v-icon',[_vm._v("mdi-backburger")]):_vm._e()],1),_c('p',[_vm._v(_vm._s(_vm.companyInfo.name)+" 관리자")])],1),_c('div',{staticClass:"tools-section"},[(_vm.uploader.filter(function (r) { return r.status === 'progress'; }).length > 0
          || _vm.uploader.filter(function (r) { return r.status === 'done'; }).length > 0)?_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"link":"","depressed":"","plain":"","rounded":"","min-width":"26","width":"40","height":"40","active-class":"tools-btn-active"},on:{"click":function($event){return _vm.uploaderShow(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"notification",attrs:{"color":"black"}},[_vm._v(" mdi-cloud-upload-outline ")]),_c('v-badge',{staticClass:"mb-6",attrs:{"color":_vm.uploader.filter(function (r) { return r.status === 'progress'; }).length > 0
                ?'info':'success',"content":_vm.uploader.filter(function (r) { return r.status === 'progress'; }).length > 0
                ?String(_vm.uploader.filter(function (r) { return r.status === 'progress'; }).length)
                :String(_vm.uploader.filter(function (r) { return r.status === 'done'; }).length),"overlap":"","bordered":""}})],1)]}}],null,false,2890361751)},[_c('span',[_vm._v("업로드")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"to":"/notification","link":"","depressed":"","plain":"","rounded":"","min-width":"26","width":"40","height":"40","active-class":"tools-btn-active"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"notification",attrs:{"color":"black"}},[_vm._v(" mdi-bell-outline ")]),(_vm.unReadCnt > 0)?_c('v-badge',{staticClass:"mb-6",attrs:{"color":"error","content":_vm.unReadCnt > 100
                ?'100+'
                :_vm.unReadCnt,"overlap":"","bordered":""}}):_vm._e()],1)]}}])},[_c('span',[_vm._v("알림센터")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"to":"/mypage","link":"","depressed":"","plain":"","rounded":"","min-width":"40","width":"40","height":"40","active-class":"tools-btn-active"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mypage",attrs:{"color":"black"}},[_vm._v(" mdi-account-outline ")])],1)]}}])},[_c('span',[_vm._v("내 정보")])])],1)]),_c('v-navigation-drawer',{staticClass:"mt-14 pb-14",attrs:{"mini-variant":_vm.mini,"permanent":"","clipped":"","color":"white","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.menus),function(menu){return _c('v-list-item',{key:menu.name,attrs:{"link":"","color":"#FFFFFF","to":("/" + (menu.path)),"active-class":"menu-list-active"}},[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mr-4"},'v-list-item-icon',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(menu.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(menu.name))])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(menu.name))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }