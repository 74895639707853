<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap">
      <p class="tit">
        {{ batch != null ? `메모일괄등록(${batch.length})` : '고객 메모' }}
        <span class="sub" v-if="batch == null">{{ target.name }}</span>
      </p>
      <div v-if="batch != null"></div>
      <div
        class="log"
        v-else-if="data != null && data.length > 0"
      >
        <div
          class="log-row"
          v-for="(row, index) in data"
          :key="index"
        >
          <span class="log-span memo-span d-flex align-center">{{ row.memo }}</span>
          <div class="info-box">
            <p>{{ makeCreatorToFullName(row.creator) }}</p>
            <p class="date">{{ time.makeLocalTime(row.date, 'sec') }}</p>
          </div>
        </div>
      </div>
      <div
        class="log"
        v-else
      >
        <p class="log-row"><span>메모가 없습니다.</span></p>
      </div>
      <div class="memo-area" :class="batch != null ? '' : 'mt-4'">
        <v-textarea
          v-model="memo"
          background-color="#f2f2f2"
          :autofocus="true"
          placeholder="메모내용을 입력해주세요."
          dense
          auto-grow
          flat
          hide-details
          color="black"
          outlined
          solo
          style="font-size: .9rem"
        ></v-textarea>
        <div class="d-flex justify-end mt-2">
          <v-btn
            color="primary"
            elevation
            :disabled="memo.trim().length <= 0"
            @click="updateMemo()"
          >
            등록
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import comm from '@/util/comm';
import time from '@/util/time';

export default {
  name: 'DlgCustomerMemo',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
    func: {
      type: Function,
    },
    batch: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    comm,
    time,
    memo: '',
    groups: [],
    staffs: [],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      memoDlg: 'dialog/memo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.$emit('update:show', false);
      this.memo = '';
    },
    getDefaultData() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.$socket.emit('users.staffs.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: [],
            sortDesc: [],
            filters: [],
          }, (res) => {
            if (res.result === 'success') {
              this.groups = resp.items.groups;
              this.staffs = [];
              res.items.forEach((r) => {
                const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                this.staffs.push({
                  id: r.id,
                  fullName: `${groupName}${r.name}`,
                });
              });
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${res.code})`]);
              console.error(res);
              this.init();
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.init();
        }
      });
    },
    makeCreatorToFullName(creator) {
      const row = this.staffs.find((r) => r.id === creator);
      return row?.id === undefined ? '시스템' : row.fullName;
    },
    updateMemo() {
      if (this.memo.trim().length > 300) {
        this.alert(['error', '최대 300자까지 등록가능합니다.']);
      } else if (this.batch == null) {
        this.progress(true);
        const memos = [...this.data];
        memos.unshift({
          memo: this.memo,
          creator: this.userInfo.id,
          date: time.makeUTCTime(),
        });
        this.$socket.emit('users.customer.update', {
          items: [{
            id: this.target.id,
            memo: JSON.stringify(memos),
          }],
        }, (resp) => {
          if (resp.result === 'success') {
            this.$emit('update:data', memos);
            this.alert(['success', '메모가 등록되었습니다.']);
            this.memo = '';
            this.func();
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      } else {
        this.progress(true);
        this.$socket.emit('users.customer.memo.update', {
          items: this.batch,
          memo: this.memo,
        }, (resp) => {
          console.log(resp);
          if (resp.result === 'success') {
            this.alert(['success', '메모가 등록되었습니다.']);
            this.init();
            this.func();
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      }
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) this.getDefaultData();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.log-wrap {
  padding: 15px;
  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0;
  }
  .sub {
    font-size: 0.9rem;
    margin-top: -8px;
    margin-left: 4px;
    margin-bottom: 6px;
  }
  .log {
    max-height: 500px;
    overflow: auto;
    .log-row {
      background-color: #f2f2f2;
      padding: 10px;
      margin: 0;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      min-width: 400px;
      & + .log-row {
        margin-top: 4px;
      }
      span {
        font-size: 0.9rem;
        color: #555;
        & + .info-box {
          margin-left: 40px;
        }
      }
      span.log-span {
        color: #262626;
        font-weight: 500;
        &.memo-span {
          white-space: pre-wrap;
          max-width: 400px;
        }
      }
    }
    .info-box {
      p {
        text-align: right;
        font-size: 0.8rem;
        margin: 0;
        color: #222;
        &.date {
          color: #888;
        }
      }
    }
  }
  .memo-area {
    min-width: 400px;
  }
}
</style>
