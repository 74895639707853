<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap">
      <p class="tit">
        {{ title }}
        <span class="sub" v-if="sub !== null">{{ sub }}</span>
      </p>
      <div class="log" v-if="data.length > 0">
        <p v-for="(row, index) in data" :key="index" class="log-row">
          <span class="log-span d-flex align-center">
            {{ row.after }}
            <div
              v-if="row.paymentInfo != undefined && row.paymentInfo.cardType != undefined"
              class="d-flex align-center"
            >
              <v-tooltip top color="black">
                <template #activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on"
                    label x-small
                    class="ml-2 pl-2 pr-2"
                  >
                    상세
                  </v-chip>
                </template>
                <span v-if="row.paymentInfo.cardType === 0">
                  {{ `카드사: ${row.paymentInfo.company}` }}<br/>
                  {{ `할부: ${row.paymentInfo.installment}개월` }}<br/>
                  {{ `전화번호: ${comm.makePhoneNumber(row.paymentInfo.phone)}` }}
                </span>
                <span v-if="row.paymentInfo.cardType === 1">
                  {{ `카드사: ${row.paymentInfo.company}` }}<br/>
                  {{ `할부: ${row.paymentInfo.installment}개월` }}<br/>
                  {{ `전화번호: ${comm.makePhoneNumber(row.paymentInfo.phone)}` }}<br/>
                  {{ `카드번호: ${row.paymentInfo.cardNumber}` }}<br/>
                  {{ `비밀번호: ${row.paymentInfo.password}` }}<br/>
                  {{ `생년월일: ${row.paymentInfo.birthday}` }}<br/>
                </span>
              </v-tooltip>
            </div>
            <div
              v-else-if="row.rooms != undefined && row.rooms.length > 0"
              class="d-flex align-center"
            >
              <v-tooltip top color="black">
                <template #activator="{ on }">
                  <v-chip v-on="on"
                    label x-small
                    class="ml-2 pl-2 pr-2"
                  >
                    상세
                  </v-chip>
                </template>
                <span>
                  <span v-for="(room, index) in row.rooms" :key="index">
                    {{ room.name }}<br/>
                  </span>
                </span>
              </v-tooltip>
            </div>
          </span>
          <span>
            {{
              `${comm.getGroupFullPath(groups, row.groupNo, '그룹없음')}
              ${row.name} ${time.makeLocalTime(row.date, 'sec')}`
            }}
          </span>
        </p>
      </div>
      <div class="log" v-else>
        <p class="log-row mr-16"><span>{{ title }}이 없습니다.</span></p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import comm from '@/util/comm';
import time from '@/util/time';

export default {
  name: 'DlgHistory',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    sub: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    comm,
    time,
    groups: [],
  }),
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
    }),
    init() {
      this.$emit('update:show', false);
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.init();
        }
      });
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) this.getGroupList();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.log-wrap {
  padding:15px;
  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin:0;
  }
  .sub {
    font-size: .9rem;
    margin-top: -8px;
    margin-bottom:6px;
  }
  .log {
    max-height: 300px;
    overflow: auto;
    background-color: #f2f2f2;
    padding:10px;
    .log-row {
      margin:0;
      display: flex;
      justify-content: space-between;
      &+.log-row{
        margin-top:4px;
      }
      span {
        font-size: .8rem;
        color: #555;
        &+span {
          margin-left: 60px;
        }
      }
      span.log-span {
        color: #262626;
        font-weight: 500;
      }
    }
  }
}
</style>
