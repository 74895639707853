<template>
  <v-dialog
    v-model="result.show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    persistent
  >
    <v-card tile class="rounded-0">
      <v-toolbar
        dense
        class="toolbar"
        flat
        color="primary"
        dark
      >
        <v-btn
          icon
          @click="resultClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ result.type }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="contentBox pt-6 pb-4">
        <div>
          <div class="infoText">
            파일명: {{ result.fileName }}
            <v-chip class="mb-1 ml-2 mr-1" color="success">등록완료 {{ result.success }}건</v-chip>
            <v-chip class="mb-1" color="error">실패 {{ result.fail }}건</v-chip>
          </div>
          <div class="dupText mt-8 mb-0 d-flex justify-space-between align-center"
            v-if="result.data.length > 0"
          >
            중복으로 인한 미등록건 목록
            <v-btn
              depressed
              color="error"
              outlined
              dense
              class="pl-2 ml-2 mb-2"
              @click="excelDownload(result.data)"
            >
              <v-icon class="mr-1">
                mdi-file-download-outline
              </v-icon>중복목록 엑셀다운로드
            </v-btn>
          </div>
          <div class="msg" v-else>
            중복으로 인한 미등록건이 없습니다.
          </div>
          <v-data-table
            v-if="result.data.length > 0"
            :headers="headers"
            :items="result.data"
            dense
            :items-per-page="20"
            class="simpleTable mt-2"
          ></v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import * as XLSX from 'xlsx';

export default {
  name: 'DlgResult',
  data: () => ({
    isExcelDown: false,
    headers: [
      {
        text: '이름',
        align: 'start',
        sortable: false,
        filterble: false,
        value: 'name',
      },
      {
        text: '전화번호',
        align: 'start',
        sortable: false,
        filterble: false,
        value: 'phone',
      },
      {
        text: '기존업체',
        align: 'start',
        sortable: false,
        filterble: false,
        value: 'sourceName',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      result: 'uploader/result',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      resultCng: 'uploader/result',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    resultClose() {
      if (this.result.data.length > 0 && this.excelDown === false) {
        const func = () => {
          this.resultCng({
            data: [],
            taskId: null,
            type: null,
            fileName: null,
            success: 0,
            fail: 0,
            show: false,
          });
          this.confirm({ show: false });
        };
        this.confirm({
          show: true,
          func,
          msg: '다운로드 받지 않은 미등록 목록은 저장되지 않습니다.</br></br>결과를 정말 닫으시겠습니까?',
          btnText: '닫기',
          color: 'primary',
        });
      } else {
        this.resultCng({
          data: [],
          taskId: null,
          type: null,
          fileName: null,
          success: 0,
          fail: 0,
          show: false,
        });
      }
    },
    excelDownload(data) {
      this.progress(true);
      setTimeout(() => {
        this.isExcelDown = true;
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'DB중복목록.xlsx');
        XLSX.writeFile(workbook, 'DB중복목록.xlsx');
        this.progress(false);
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
.contentBox {
  height: 100%;
}
.toolbar {
  border-bottom: 1px solid #ccc;
}
.simpleTable {
  border:1px solid #ddd;
}
.infoText {
  color: #000;
  font-size: 1.3rem;
}
.dupText {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}
.vir-wrap{
  width: 100%;
}
.msg {
  width: 100%;
  padding:20px;
  background-color: #f2f2f2;
  border-radius: 4px;
  font-size: 1rem;
  color:#333;
  margin-top:10px;
}
</style>
