<template>
  <div>
    <div
      class="header-wrap"
      app
    >
      <div
        class="title-section"
      >
        <v-btn
          icon
          color="#FFFFFF"
          @click="onDrawer"
        >
          <v-icon v-if="drawer">mdi-menu</v-icon>
          <v-icon v-if="!drawer">mdi-backburger</v-icon>
        </v-btn>
        <p>{{ companyInfo.name }} 관리자</p>
      </div>
      <div class="tools-section">
        <v-tooltip bottom color="black"
          v-if="uploader.filter((r) => r.status === 'progress').length > 0
            || uploader.filter((r) => r.status === 'done').length > 0"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              @click="uploaderShow(true)"
              link
              depressed
              plain
              rounded
              min-width="26"
              width="40"
              height="40"
              class="mr-5"
              active-class="tools-btn-active"
            >
              <v-icon
                color="black"
                class="notification"
              >
                mdi-cloud-upload-outline
              </v-icon>
              <v-badge
                :color="uploader.filter((r) => r.status === 'progress').length > 0
                  ?'info':'success'"
                :content="uploader.filter((r) => r.status === 'progress').length > 0
                  ?String(uploader.filter((r) => r.status === 'progress').length)
                  :String(uploader.filter((r) => r.status === 'done').length)"
                overlap
                class="mb-6"
                bordered
              />
            </v-btn>
          </template>
          <span>업로드</span>
        </v-tooltip>
        <v-tooltip bottom color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              to="/notification"
              link
              depressed
              plain
              rounded
              min-width="26"
              width="40"
              height="40"
              class="mr-5"
              active-class="tools-btn-active"
            >
              <v-icon
                color="black"
                class="notification"
              >
                mdi-bell-outline
              </v-icon>
              <v-badge
                v-if="unReadCnt > 0"
                color="error"
                :content="
                  unReadCnt > 100
                  ?'100+'
                  :unReadCnt
                "
                overlap
                class="mb-6"
                bordered
              />
            </v-btn>
          </template>
          <span>알림센터</span>
        </v-tooltip>
        <v-tooltip bottom color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              to="/mypage"
              link
              depressed
              plain
              rounded
              min-width="40"
              width="40"
              height="40"
              class="mr-4"
              active-class="tools-btn-active"
            >
              <v-icon
                color="black"
                class="mypage"
              >
                mdi-account-outline
              </v-icon>
            </v-btn>
          </template>
          <span>내 정보</span>
        </v-tooltip>
      </div>
    </div>
    <v-navigation-drawer
      :mini-variant="mini"
      v-model="drawer"
      permanent
      clipped
      color="white"
      app
      class="mt-14 pb-14"
    >
      <v-list dense nav>
        <v-list-item
          v-for="menu in menus"
          :key="menu.name"
          link
          color="#FFFFFF"
          :to="`/${menu.path}`"
          active-class="menu-list-active"
        >
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-list-item-icon
              class="mr-4"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon color="primary">{{ menu.icon }}</v-icon>
            </v-list-item-icon>
          </template>
          <span>{{ menu.name }}</span>
        </v-tooltip>
          <v-list-item-content>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'NavSection',
  data: () => ({
    drawer: false,
    mini: true,
  }),
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
      route: 'router/route',
      menus: 'auth/menus',
      notifications: 'notification/notifications',
      uploader: 'uploader/list',
      unReadCnt: 'notification/unReadCnt',
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapMutations({
      uploaderShow: 'uploader/show',
    }),
    ...mapActions({
      getUnreadCnt: 'notification/getUnreadCnt',
    }),
    onDrawer() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.drawer = !this.drawer;
        this.mini = !this.mini;
      } else {
        this.drawer = !this.drawer;
        this.mini = !this.mini;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getUnreadCnt();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
  .header-wrap{
    position: fixed;
    padding:0px 20px;
    padding-left: 0px;
    top:0px;
    width: 100vw;
    height: 56px;
    border-bottom:1px solid #ddd;
    left: 0px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    .title-section {
      display: flex;
      align-items: center;
      .v-btn {
        width: 56px;
        height: 56px;
        background-color: #0031B2;
        border-radius: 0px;
        .v-icon {
          font-size: 2rem;
        }
      }
      p{
        margin:0;
        margin-bottom:0px;
        font-weight: 700;
        font-size: 1.3rem;
        margin-left:10px;
      }
    }
    .tools-section {
      .tools-btn-active {
        .v-icon {
          color: #0031B2 !important;
        }
      }
      .notification{
        font-size: 30px;
        cursor: pointer;
      }
      .mypage {
        font-size: 36px;
        margin-left: 30px;
        margin-right: 30px;
        margin-top:2px;
        cursor: pointer;
      }
    }
  }
  .menu-list-active{
    background-color: #0031B220;
    .v-list-item__title{
      color:#000;
    }
  }
</style>
