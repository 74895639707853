<template>
  <div class="modalWrap"
    :class="uploaderShow === true?'on':''"
    v-if="uploader.filter((r) => r.status === 'progress').length > 0
      || uploader.filter((r) => r.status === 'done').length > 0"
  >
    <v-card
      width="300"
      class="mx-auto pa-2 overflow-y-auto"
      max-height="200"
      outlined
      elevation="3"
    >
      <v-chip class="mb-2 pl-2 pr-2" small color="info"
        v-if="uploader.filter((r) => r.status === 'progress').length > 0"
      >
        업로드중 {{ uploader.filter((r) => r.status === 'progress').length }}
      </v-chip>
      <div class="conBox"
        v-for="item in uploader.filter((r) => r.status === 'progress')"
        :key="item.taskId"
      >
        <div class="d-flex align-center mb-2">
          <v-chip class="pr-2 pl-2 fileType" small label>{{ item.type }}</v-chip>
          <p class="fileName">{{ item.fileName }}</p>
        </div>
        <v-progress-linear
          color="info"
          buffer-value="0"
          :value="(item.progress/item.total) * 100"
          stream
        ></v-progress-linear>
      </div>
      <v-chip class="mb-2 pl-2 pr-2" small color="success"
        :class="uploader.filter((r) => r.status === 'progress').length > 0?'mt-4':''"
        v-if="uploader.filter((r) => r.status === 'done').length > 0"
      >
        완료 {{ uploader.filter((r) => r.status === 'done').length }}
      </v-chip>
      <div class="conBox"
        v-for="item in uploader.filter((r) => r.status === 'done')"
        :key="item.taskId"
      >
        <div class="d-flex align-center">
          <v-chip class="pr-2 pl-2 fileType" small label>DB등록</v-chip>
          <p class="fileName">{{ item.fileName }}</p>
          <v-chip small class="pr-1 pl-1 fileResult" outlined label link
            color="success"
            @click="resultModalOpen(item)"
          >결과보기</v-chip>
        </div>
      </div>
    </v-card>
    <div class="d-flex justify-end">
      <v-btn dense x-small color="#555"
        elevation="3" dark depressed class="mt-2 mr-1 pl-2 pr-2 pt-2 pb-2"
        @click="uploaderShowCng(false)"
      >
        <v-icon small dense>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'DlgUploadTask',
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      uploader: 'uploader/list',
      uploaderShow: 'uploader/show',
    }),
  },
  methods: {
    ...mapMutations({
      uploaderCng: 'uploader/list',
      uploaderShowCng: 'uploader/show',
      result: 'uploader/result',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    resultModalOpen(item) {
      this.uploaderShowCng(false);
      const data = item.duplicated;
      this.result({
        taskId: item.taskId,
        type: 'DB관리',
        fileName: item.fileName,
        success: item.total - data.length,
        fail: data.length,
        data,
        show: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .totalCnt {
    margin:0;
    padding-left: 4px;
    font-size: .8rem;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .fileName {
    width: 100%;
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin:0;
    margin-left: 4px;
    font-size: .8rem;
  }
  .fileType, .fileResult {
    flex-shrink: 0;
  }
  .conBox {
    padding:10px;
    border-radius: 4px;
    background-color: #fff;
    border:1px solid #ddd;
    & + .conBox {
      margin-top:8px;
    }
  }
  .modalWrap {
    transform: scale(0);
    transform-origin: 46% 0;
    z-index: 500;
    position: fixed;
    right: 10px;
    top: 65px;
    transition: .3s ease-in-out;
    opacity: 0;
    &.on {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>
