const getHost = () => (window.location.origin);

export default {
  apiHostBase: (() => ((process.env.NODE_ENV === 'production')
    ? '/comm'
    : '/comm'))(),
  apiHost: getHost(),
  imagePath: (() => ((process.env.NODE_ENV === 'production')
    ? '/image'
    : '/image'))(),
  smsTemplateHost: 'https://msg.bibc.co.kr/comm/templates/list',
};
