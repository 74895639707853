import moment from 'moment';

const dateTypeObj = {
  date: 'YYYY-MM-DD',
  min: 'YYYY-MM-DD HH:mm',
  sec: 'YYYY-MM-DD HH:mm:ss',
  stamp: 'YYYY-MM-DDTHH:mm:ss.000Z',
};
export default {
  moment,
  makeDate(timestamp) {
    return timestamp.slice(0, 10);
  },
  makeTime(timestamp) {
    return timestamp.slice(11, 16);
  },
  makeLocalTimestamp(timestamp) {
    const utcTime = new Date(timestamp);
    const utc = moment.utc(utcTime).toDate();
    return moment(utc).format('YYYY-MM-DDTHH:mm:ss.000Z');
  },
  makeLocalDate(timestamp) {
    return this.makeDate(this.makeLocalTimestamp(timestamp));
  },
  makeUTCDate(timestamp) {
    return moment.utc(timestamp).format('YYYY-MM-DD');
  },
  makeLocalTime(timestamp, type = 'sec') {
    const utcTime = new Date(timestamp);
    const utc = moment.utc(utcTime).toDate();
    return moment(utc).format(dateTypeObj[type]);
  },
  makeUTCTime(type = 'stamp') {
    const utcTime = new Date();
    return moment.utc(utcTime).format(dateTypeObj[type]);
  },
};
